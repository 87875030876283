import fetchData from "shared/data/fetchData"

const fetchDriver = async (id, auth) => {
  try {
    const driverData = await fetchData({
      endpoint: `drivers/${id}`,
      token: auth.token,
      dataKey: "driver",
    })

    return driverData
  } catch (err) {
    console.error("Error fetching driver:", err)
    throw err // Propagate error to the caller
  }
}

export default fetchDriver
