import React, { useEffect, useState, useRef, useContext } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import Loader from "shared/components/UIElements/Loader"
import GoogleMapReact from "google-map-react"
import html2canvas from "html2canvas"

const decodePoly = require("@mapbox/polyline")

const EditRoute = () => {
  const { id } = useParams()
  const auth = useContext(AuthContext)
  const mapContainerRef = useRef()

  const [route, setRoute] = useState(null)
  const markersMap = useRef([])
  const routePolyline = useRef(null)

  const [markers, setMarkers] = useState([])
  const [mapReference, setMapReference] = useState(null)
  const [mapsReference, setMapsReference] = useState(null)
  const [center] = useState({ lat: 22.24, lng: -102.85 })
  const [isCalculated, setIsCalculated] = useState(true)

  const [hourDeparture, setHourDeparture] = useState(8)
  const [minuteDeparture, setMinuteDeparture] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    document.title = "Editar Ruta"
    if (auth.token && mapReference) {
      fetchRoute()
    }
  }, [auth.token, mapsReference])

  /** set info server */
  const fetchRoute = async () => {
    try {
      setIsLoading(true)
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/${id}`,
        method: "GET",
      })

      if (response.status === 200) {
        setRoute(response.data.route)
        setMarkers(response.data.route.points)
        setHourDeparture(response.data.route.hour)
        setMinuteDeparture(response.data.route.minute)
        response.data.route.points.forEach((point) => {
          let lat = parseFloat(point.lat)
          let lng = parseFloat(point.lng)
          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            map: mapReference,
            label: {
              color: "white",
              fontWeight: "bold",
              text: point.name || "N/A",
              className: "marker-label",
            },
            icon: {
              labelOrigin: new window.google.maps.Point(11, 50),
              size: new window.google.maps.Size(22, 40),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(11, 40),
            },
          })
          markersMap.current.push(marker)
        })

        const decodedPolyline = decodePoly.decode(response.data.route.overview)

        const latLngPoly = decodedPolyline.map((coord) => {
          return { lat: coord[0], lng: coord[1] }
        })

        routePolyline.current = new window.google.maps.Polyline({
          path: latLngPoly,
          geodesic: true,
          strokeColor: "#58b0b5",
          map: mapReference,
        })

        const bounds = new window.google.maps.LatLngBounds()
        for (var i = 0; i < routePolyline.current.getPath().getLength(); i++) {
          bounds.extend(routePolyline.current.getPath().getAt(i))
        }
        mapReference.fitBounds(bounds)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  /** Markers manage */
  const addMarker = ({ x, y, lat, lng, event }) => {
    const marker = new window.google.maps.Marker({
      position: { lat, lng },
      map: mapReference,
      label: {
        color: "white",
        fontWeight: "bold",
        text: `${markers.length + 1}`,
        className: "marker-label",
      },
      icon: {
        labelOrigin: new window.google.maps.Point(11, 50),
        size: new window.google.maps.Size(22, 40),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(11, 40),
      },
    })
    markersMap.current.push(marker)

    setMarkers((markers) => [...markers, { name: "", lat: lat, lng: lng }])
    if (routePolyline.current !== null) {
      routePolyline.current.setMap(null)
      routePolyline.current = null
    }
    setIsCalculated(false)
  }

  const centerBounds = () => {
    const bounds = new mapsReference.LatLngBounds()
    markers.forEach((marker) => {
      bounds.extend(new mapsReference.LatLng(marker.lat, marker.lng))
    })
    mapReference.fitBounds(bounds)
  }

  function sumMinutes(hora, minutosASumar) {
    // Divide la hora y los minutos en partes separadas
    const [horaStr, minutosStr] = hora.split(":")

    // Convierte las partes en números enteros
    const horaInt = parseInt(horaStr)
    const minutosInt = parseInt(minutosStr)

    // Crea un objeto Date con la hora original
    const fechaHoraOriginal = new Date()
    fechaHoraOriginal.setHours(horaInt)
    fechaHoraOriginal.setMinutes(minutosInt)

    // Suma los minutos a la hora original
    const fechaHoraSumada = new Date(
      fechaHoraOriginal.getTime() - minutosASumar * 60000
    )

    // Obtiene la hora y minutos de la hora sumada
    const horaSumada = fechaHoraSumada.getHours()
    const minutosSumados = fechaHoraSumada.getMinutes()

    // Formatea la hora resultante en formato HH:mmAM/PM
    const amPm = horaSumada >= 12 ? "PM" : "AM"
    const horaFormateada = horaSumada % 12 || 12 // Convierte a formato de 12 horas
    const minutosFormateados = minutosSumados.toString().padStart(2, "0") // Asegura que los minutos sean dos dígitos

    return `${horaFormateada}:${minutosFormateados}${amPm}`
  }

  const handlePrintMap = async () => {
    if (mapContainerRef.current) {
      try {
        // Add a delay to ensure the map and its elements are fully rendered
        await new Promise((resolve) => setTimeout(resolve, 500))

        const canvas = await html2canvas(mapContainerRef.current, {
          useCORS: true,
          allowTaint: true,
          scale: 2,
        })

        const imageData = canvas.toDataURL("image/png")

        // Open a new window to display the image
        const printWindow = window.open("", "_blank")
        printWindow.document.write(`
        <html>
          <head>
            <title>Print Map</title>
            <style>
              body {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                background-color: #fff;
              }
              img {
                max-width: 100%;
                max-height: 100%;
              }
            </style>
          </head>
          <body>
            <img src="${imageData}" alt="Map Image" />
          </body>
        </html>
      `)

        // Wait for the new window's content to load before triggering the print
        printWindow.document.close()
        printWindow.onload = () => {
          printWindow.print()
        }
      } catch (error) {
        console.error("Error capturing the map:", error)
        alert("There was an error capturing the map. Please try again.")
      }
    } else {
      alert("Error: The map container is not initialized.")
    }
  }

  return (
    <div>
      {isLoading && <Loader asOverlay />}
      {error && (
        <div className="columns">
          <div className="column">
            <div className="notification is-danger is-light">
              Error: {error}
            </div>
          </div>
        </div>
      )}
      <div className="columns">
        <div className="buttons are-small has-addons">
          {markers.length > 1 && isCalculated && (
            <button className="button" type="button" onClick={centerBounds}>
              <span class="icon is-small">
                <i class="fas fa-map-marker-alt"></i>
              </span>
              <span>Centrar</span>
            </button>
          )}
          <button
            className="button is-small is-primary"
            onClick={handlePrintMap}
          >
            Print Map
          </button>
        </div>
      </div>
      <div id="map-container" ref={mapContainerRef} style={{ height: "100%" }}>
        <div className="columns">
          <div className="column">
            {route && (
              <div style={{ width: "100%" }}>
                {markers && markers.length > 0 && (
                  <table className="table is-fullwidth">
                    <tr>
                      <th>Estación</th>
                      <th>Hora de llegada (aprox)</th>
                    </tr>
                    {markers.map((marker, index) => (
                      <tr key={index}>
                        <td>{marker.name}</td>
                        <td>
                          {sumMinutes(
                            `${hourDeparture}:${minuteDeparture}`,
                            marker.time
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>
                )}
              </div>
            )}
          </div>
          <div className="column is-three-quarters" style={{ paddingTop: "0" }}>
            {error && (
              <p className="notification is-danger is-light">{error}</p>
            )}
            <div id="map">
              <h1
                style={{
                  width: "49%",
                  display: "inline-block",
                  marginBottom: "0",
                }}
              >
                {route && route.name}
              </h1>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_API_KEY,
                }}
                center={center}
                zoom={6}
                yesIWantToUseGoogleMapApiInternals
                onClick={addMarker}
                onGoogleApiLoaded={({ map, maps }) => {
                  setMapReference(map)
                  setMapsReference(maps)
                }}
              ></GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditRoute
