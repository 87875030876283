import React from "react"

import "./PrivacyPolicy.css"

const PrivacyNotice = () => {
  return (
    <div
      className="privacy-container container"
      style={{
        maxWidth: "100%",
        margin: "0 auto",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        color: "#333",
      }}
    >
      <div
        style={{
          padding: "20px",
          border: "1px solid #ddd",
          borderRadius: "5px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Política de Privacidad</h1>
        <p>
          En <strong>MiBus Conductor</strong>, respetamos y protegemos la
          privacidad de nuestros usuarios. Esta Política de Privacidad explica
          cómo recopilamos, usamos y protegemos la información personal que
          usted nos proporciona al usar nuestra aplicación.
        </p>
        <section>
          <h2>1. Responsable del Tratamiento de Datos</h2>
          <p>
            <strong>MiBus Conductor</strong> desarrollo de la aplicación a cargo
            de <strong>Juan Carlos Limon Nieto</strong>.<strong> MiBus </strong>
            con domicilio en Cda Fresnillo 134, Col. San José, México, es el
            responsable del tratamiento de sus datos personales. Si tiene alguna
            pregunta sobre este Aviso de Privacidad, puede contactarnos en{" "}
            <a href="mailto:contacto@mytrackinbus.com">
              contacto@mytrackinbus.com
            </a>
            .
          </p>
        </section>
        <section>
          <h2>2. Datos Personales que Recopilamos</h2>
          <p>
            <strong>MiBus Conductor</strong> recopila los siguientes datos
            personales para garantizar el funcionamiento adecuado de la
            aplicación:
          </p>
          <ul style={{ paddingLeft: "20px" }}>
            <li>Nombre completo</li>
            <li>Número de teléfono</li>
            <li>Dirección de origen y destino</li>
            <li>Ubicación en tiempo real</li>
            <li>Correo electrónico</li>
          </ul>
        </section>
        <section>
          <h2>3. Finalidad del Tratamiento de Datos</h2>
          <p>
            Los datos recopilados se utilizan exclusivamente para los siguientes
            fines:
          </p>
          <ul style={{ paddingLeft: "20px" }}>
            <li>Proporcionar el servicio de transporte solicitado.</li>
            <li>Monitorear el trayecto de los autobuses en tiempo real.</li>
            <li>Garantizar la seguridad de los usuarios durante el viaje.</li>
            <li>Comunicar actualizaciones importantes sobre el servicio.</li>
          </ul>
        </section>
        <section>
          <h2>4. Transferencia de Datos</h2>
          <p>
            <strong>MiBus Conductor</strong> no comparte tus datos personales
            con terceros sin tu consentimiento, salvo cuando sea requerido por
            ley o regulaciones aplicables.
          </p>
        </section>
        <section>
          <h2>5. Derechos del Usuario</h2>
          <p>
            Tienes derecho a acceder, rectificar, cancelar u oponerte al
            tratamiento de tus datos personales, conforme a la legislación
            vigente (Derechos ARCO). Para ejercer estos derechos, escríbenos a{" "}
            <a href="mailto:contacto@mytrackingbus.com">
              contacto@mytrackingbus.com
            </a>
            .
          </p>
        </section>
        <section>
          <h2>6. Seguridad de los Datos</h2>
          <p>
            Implementamos medidas técnicas, administrativas y físicas para
            proteger sus datos personales contra acceso no autorizado, pérdida o
            alteraciones.
          </p>
        </section>
        <section>
          <h2>7. Cambios al Aviso de Privacidad</h2>
          <p>
            Podemos actualizar este Aviso de Privacidad periódicamente para
            reflejar cambios en nuestras prácticas o en la legislación
            aplicable. Notificaremos cualquier modificación a través de nuestra
            aplicación y actualizaremos la fecha de revisión al inicio del
            documento.
          </p>
        </section>
        <section>
          <h2>8. Política de Retención y Eliminación de Datos</h2>
          <p>
            Conservamos tus datos personales únicamente durante el tiempo
            necesario para cumplir con los fines mencionados en este aviso o
            conforme a lo que la ley establezca. Si decides eliminar tu cuenta,
            tus datos serán eliminados de forma permanente, salvo que deban
            conservarse por requerimientos legales.
          </p>
        </section>
        <section>
          <h2>9. Contacto</h2>
          <p>
            Si tienes dudas o inquietudes sobre este Aviso de Privacidad o el
            manejo de tus datos personales, por favor contáctanos a través de:
          </p>
          <p>
            Correo electrónico:{" "}
            <a href="mailto:contacto@mytrackingbus.com">
              contacto@mytrackingbus.com
            </a>
          </p>
          <p>
            Sitio web: <a href="https://mytrackingbus.com">mytrackingbus.com</a>
          </p>
        </section>
        <section>
          <h2>Acceso al aviso de privacidad</h2>
          <p>
            Este Aviso de Privacidad está disponible en la página de la
            aplicación en Google Play y dentro de la aplicación misma. También
            puedes consultarlo directamente en el siguiente enlace:{" "}
            <a href="https://mytrackingbus.com/privacy-policy">
              https://mytrackingbus.com/privacy-policy
            </a>
          </p>
        </section>
        <p style={{ textAlign: "center", marginTop: "20px" }}>
          Al usar nuestra aplicación, usted acepta este Aviso de Privacidad y el
          tratamiento de sus datos personales conforme a los términos aquí
          descritos.
        </p>
      </div>
    </div>
  )
}

export default PrivacyNotice
