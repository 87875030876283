import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import { useForm } from "shared/hooks/formHook"
import { useNavigate, useParams } from "react-router-dom"
import { AuthContext } from "shared/context/auth-context"
import { VALIDATOR_REQUIRED } from "shared/utils/validator"
import Input from "shared/components/FormElements/Input"
import Loader from "shared/components/UIElements/Loader"

const EditRoute = () => {
  const { id } = useParams()
  const [route, setRoute] = useState(null)

  const navigate = useNavigate()
  const auth = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [formState, inputHandler] = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      company_id: {
        value: "",
        isValid: false,
      },
    },
    false
  )

  useEffect(() => {
    document.title = "Editar Ruta"
    if (!auth.token) {
      return
    }

    getRoute()
  }, [auth.token])

  /** set info server */
  const getRoute = async () => {
    try {
      setIsLoading(true)
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/${id}`,
        method: "GET",
      })

      if (response.status === 200) {
        setRoute(response.data.route)
      }
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout()
      }
      setError(err.response.data.message)
    }
    setIsLoading(false)
  }

  const updateRoute = async () => {
    setIsLoading(true)

    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        baseURL: `${process.env.REACT_APP_API_URL}/routes/${id}`,
        method: "PUT",
        data: {
          name: formState.inputs.name.value,
        },
      })

      if (response.status === 201) {
        navigate("/routes", { replace: true })
      } else {
        setError("Ocurrío un error innesperado")
      }
    } catch (err) {
      setError(err.response.data.message || "Ocurrío un error innesperado")
    }

    setIsLoading(false)
  }

  return (
    <div>
      {isLoading && <Loader asOverlay />}
      {error && (
        <div className="columns">
          <div className="column">
            <div className="notification is-danger is-light">
              Error: {error}
            </div>
          </div>
        </div>
      )}
      <div className="columns">
        <div className="column is-half-desktop">
          <h1>Editar Datos Ruta - {route && route.name}</h1>
          <div>
            {route && route.name && (
              <div>
                <label htmlFor="">Nombre de la ruta </label>
                <Input
                  type="text"
                  value={route.name}
                  id="name"
                  placeholder="Nombre"
                  validators={[VALIDATOR_REQUIRED()]}
                  onInput={inputHandler}
                  errorText="Este campo es obligatorio"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditRoute
